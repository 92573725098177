import React from "react";
import {
  TIMER_PREFERENCE_VALUES,
  TIMER_PREFERENCE_DEFAULT,
} from "../../constants";

const ClassicTimer = React.lazy(() => import("./classic/Classic"));
const DigitalTimer = React.lazy(() => import("./digital/Digital"));
const DotMatrix = React.lazy(() => import("./dotmatrix/DotMatrix"));
const LargeTimer = React.lazy(() => import("./largebar/LargeBar"));
const SlowTimer = React.lazy(() => import("./slow/Slow"));
const SunsetTimer = React.lazy(() => import("./sunset/Sunset"));
const UglyTimer = React.lazy(() => import("./ugly/Ugly"));
const BSODTimer = React.lazy(() => import ("./bsod/BSOD"));

const TIMER_MAP = {
  [TIMER_PREFERENCE_VALUES.BSOD]: <BSODTimer />,
  [TIMER_PREFERENCE_VALUES.Classic]: <ClassicTimer />,
  [TIMER_PREFERENCE_VALUES.Digital]: <DigitalTimer />,
  [TIMER_PREFERENCE_VALUES.DotMatrix]: <DotMatrix />,
  [TIMER_PREFERENCE_VALUES.LargeBar]: <LargeTimer />,
  [TIMER_PREFERENCE_VALUES.Slow]: <SlowTimer />,
  [TIMER_PREFERENCE_VALUES.Sunset]: <SunsetTimer />,
  [TIMER_PREFERENCE_VALUES.Ugly]: <UglyTimer />,
};

export const TIMER_LABELS = new Map([
  [TIMER_PREFERENCE_VALUES.Classic, "Classic"],
  [TIMER_PREFERENCE_VALUES.BSOD, "CrowdStrike"],
  [TIMER_PREFERENCE_VALUES.Digital, "Digital"],
  [TIMER_PREFERENCE_VALUES.DotMatrix, "Dot Matrix"],
  [TIMER_PREFERENCE_VALUES.LargeBar, "Progress Bar"],
  [TIMER_PREFERENCE_VALUES.Slow, "Slow"],
  [TIMER_PREFERENCE_VALUES.Sunset, "Sunset"],
  [TIMER_PREFERENCE_VALUES.Ugly, "Ugly"],
]);

export function getTimer(timerPreference = TIMER_PREFERENCE_DEFAULT) {
  return TIMER_MAP[timerPreference] || TIMER_MAP[TIMER_PREFERENCE_DEFAULT];
}

export function getTimerLabel(timerPreference = TIMER_PREFERENCE_DEFAULT) {
  return (
    TIMER_LABELS.get(timerPreference) ||
    TIMER_LABELS.get(TIMER_PREFERENCE_DEFAULT)
  );
}
