import "./Settings.css";

import React, { useCallback, useMemo, useRef } from "react";
import { requestNotifications } from "../../notifications/Notifications";
import { getTimerLabel, TIMER_LABELS } from "../timers/getTimer";
import {
  getSoundFile,
  getSoundLabel,
  SOUND_LABELS,
} from "../timers/common/getSound";

function SettingsSection({
  isUserNudgePermanentlyDismissed,
  mutedPreference,
  notificationsEnabled,
  notify,
  setIsUserNudgePermanentlyDismissed,
  setMuted,
  setNotify,
  setSound,
  setTheme,
  setVolume,
  sound,
  theme,
  volume,
}) {
  const toggleMuted = useCallback(
    (event) => {
      event.stopPropagation();
      event.preventDefault();
      setMuted(!mutedPreference);
    },
    [setMuted, mutedPreference]
  );

  const updateMuted = useCallback(
    (event) => {
      const checked = event.target.checked;
      setMuted(checked);
    },
    [setMuted]
  );

  const toggleNotify = useCallback(
    (event) => {
      event.stopPropagation();
      event.preventDefault();
      setNotify(!notify);
    },
    [setNotify, notify]
  );

  const updateNotify = useCallback(
    (event) => {
      const checked = event.target.checked;
      setNotify(checked);
    },
    [setNotify]
  );

  const toggleUserNudge = useCallback(
    (event) => {
      event.stopPropagation();
      event.preventDefault();
      setIsUserNudgePermanentlyDismissed(!isUserNudgePermanentlyDismissed);
    },
    [setIsUserNudgePermanentlyDismissed, isUserNudgePermanentlyDismissed]
  );

  const updateUserNudge = useCallback(
    (event) => {
      const checked = event.target.checked;
      setIsUserNudgePermanentlyDismissed(checked);
    },
    [setIsUserNudgePermanentlyDismissed]
  );

  const updateVolume = useCallback(
    (event) => {
      const value = event.target.value / 100;
      setVolume(value);
    },
    [setVolume]
  );

  const audioEl = useRef();

  const testAudio = useCallback(() => {
    audioEl.current.volume = volume;
    audioEl.current.play();
  }, [audioEl, volume]);

  const updateTheme = useCallback(
    (event) => {
      const value = event.target.value;
      setTheme(value);
    },
    [setTheme]
  );

  const themeLabel = useMemo(() => {
    return getTimerLabel(theme);
  }, [theme]);

  const updateSound = useCallback(
    (event) => {
      const value = event.target.value;
      setSound(value);
    },
    [setSound]
  );

  const soundFile = useMemo(() => {
    return getSoundFile(sound);
  }, [sound]);

  const soundLabel = useMemo(() => {
    return getSoundLabel(sound);
  }, [sound]);

  return (
    <div className="EggTimer-settings section">
      <h1>Settings</h1>
      <article>
        <div>
          <p>
            <span className="label">Timer Theme: { themeLabel }</span>
          </p>
          <p>
            <label htmlFor="theme-select">Theme </label>
            <select
              aria-label="Timer Theme"
              id="theme-select"
              onChange={updateTheme}
              title="Select a timer theme"
              value={theme}
            >
              {[...TIMER_LABELS.entries()].map(([key, label]) => {
                return (
                  <option key={key} value={key}>
                    {label}
                  </option>
                );
              })}
            </select>
          </p>
          <p>
            <span className="description">
              Change the default theme of the timer.
            </span>
          </p>
        </div>
        <div>
          <p>
            <span className="label">Alert Sound: {soundLabel}</span>
          </p>
          <p>
            <label htmlFor="theme-select">Sound </label>
            <select
              aria-label="Alert Sound"
              id="sound-select"
              onChange={updateSound}
              title="Select an alert sound"
              value={sound}
            >
              {[...SOUND_LABELS.entries()].map(([key, label]) => {
                return (
                  <option key={key} value={key}>
                    {label}
                  </option>
                );
              })}
            </select>
          </p>
          <p>
            <span className="description">
              Change the default alert sound at the end of the timer.
            </span>
          </p>
        </div>
        <div>
          <p>
            <span className="label">Mute Alert Sound</span>
            <label className="switch" tabIndex={0} onKeyPress={toggleMuted}>
              <input
                aria-label="Mute Alert Sound"
                checked={mutedPreference}
                type="checkbox"
                onChange={updateMuted}
              />
              <span className="slider round" />
            </label>
            <span className="value">
              {mutedPreference ? "Muted" : "Not Muted"}
            </span>
          </p>
          <p>
            <span className="description">
              Sets the alert sound to be muted by default.
            </span>
          </p>
        </div>
        <div>
          <p>
            <span className="label">Alert Sound Volume</span>
          </p>
          <p>
            <input
              aria-label="Alert Sound Volume"
              id="volume"
              type="range"
              min="0"
              max="100"
              step="1"
              value={volume * 100}
              onChange={updateVolume}
            />
            <label htmlFor="volume"> Volume</label>
            <span>
              {" "}
              <button onClick={testAudio}>Test Audio</button>
            </span>
          </p>
          <p>
            <span className="description">
              Changes the default volume of the alert sound.
            </span>
          </p>
          <audio key={soundFile} ref={audioEl} preload="auto">
            <source
              src={`${process.env.PUBLIC_URL}/${soundFile}.mp3`}
              type="audio/mpeg"
            />
            <source
              src={`${process.env.PUBLIC_URL}/${soundFile}.ogg`}
              type="audio/ogg"
            />
          </audio>
        </div>
        <div>
          <p>
            <span className="label">Notifications</span>
            <label className="switch" tabIndex={0} onKeyPress={toggleNotify}>
              <input
                aria-label="Enable Notifications"
                checked={notify}
                type="checkbox"
                onChange={updateNotify}
              />
              <span className="slider round" />
            </label>
            <span className="value">{notify ? "Enabled" : "Disabled"}</span>
          </p>
          <p>
            <span className="description">
              Turns on or off browser notifications including the default alert
              box.
              {!notificationsEnabled && (
                <span>
                  {" "}
                  You can also{" "}
                  <button onClick={requestNotifications}>
                    enable system notifications.
                  </button>
                </span>
              )}
            </span>
          </p>
        </div>
        <div>
          <p>
            <span className="label">Hide Interaction Nudge</span>
            <label className="switch" tabIndex={0} onKeyPress={toggleUserNudge}>
              <input
                aria-label="Hide Interaction Nudge"
                checked={isUserNudgePermanentlyDismissed}
                type="checkbox"
                onChange={updateUserNudge}
              />
              <span className="slider round" />
            </label>
            <span className="value">
              {isUserNudgePermanentlyDismissed ? "Hidden" : "Not Hidden"}
            </span>
          </p>
          <p>
            <span className="description">
              Turns on or off the user interaction nudge that shows when going
              directly to a timer URL.
            </span>
          </p>
        </div>
      </article>
    </div>
  );
}

export default React.memo(SettingsSection);
