import "./ToggleButton.css";

import React, { useCallback, useEffect, useMemo, useRef } from "react";
import classnames from "classnames";
import ReactTooltip from "react-tooltip";

function ToggleButton({
  offIcon,
  offTitle,
  on,
  onIcon,
  onTitle,
  onToggle,
  tooltipProps,
}) {
  const onButtonClick = useCallback(
    (e) => {
      onToggle(!on);
    },
    [on, onToggle]
  );

  const [onClass, offClass] = useMemo(() => {
    const onClass = classnames("UI-toggle-switch-on", {
      selected: on,
    });

    const offClass = classnames("UI-toggle-switch-off", {
      selected: !on,
    });

    return [onClass, offClass];
  }, [on]);

  const { extraTipValue } = tooltipProps;
  const {
    "data-for": dataFor,
    "data-tip": dataTip,
    settings,
    show: showTooltip,
  } = tooltipProps;
  useEffect(() => {
    setImmediate(() => {
      ReactTooltip.rebuild();
    });
  }, [dataTip, dataFor]);

  const toggleRef = useRef();
  useEffect(() => {
    if (showTooltip) {
      setImmediate(() => {
        ReactTooltip.show(toggleRef.current);
      });
    } else {
      setImmediate(() => {
        ReactTooltip.hide(toggleRef.current);
      });
    }
  }, [showTooltip, toggleRef]);

  return (
    <>
      <div
        aria-checked={on}
        aria-label={onTitle}
        className="UI-toggle-switch"
        data-for={dataFor}
        data-tip={dataTip}
        onClick={onButtonClick}
        onKeyPress={onButtonClick}
        ref={toggleRef}
        role="switch"
        tabIndex="0"
      >
        <button className={offClass} tabIndex="-1" title={offTitle}>
          {offIcon}
        </button>
        <button className={onClass} tabIndex="-1" title={onTitle}>
          {onIcon}
        </button>
      </div>
      <ReactTooltip
        clickable={Boolean(extraTipValue)}
        delayShow={120}
        effect="solid"
        id={dataFor}
        place="top"
        type="dark"
        {...settings}
      >
        {extraTipValue}
      </ReactTooltip>
    </>
  );
}

export default React.memo(ToggleButton);
