import {
  DAYS_IN_MILLISECONDS,
  DAYS_IN_MONTH_NON_LEAPYEAR,
  HOURS_IN_MILLISECONDS,
  MILLISECONDS,
  MINUTES_IN_MILLISECONDS,
  SECONDS_IN_MILLISECONDS,
} from "./constants";

function isLeapYear(year) {
  return year > 0 && !(year % 4) && (year % 100 || !(year % 400));
}

function calcTotalMonths(totalDays, startDate) {
  const sMonth = startDate.getMonth();
  const sYear = startDate.getFullYear();
  let cMonth = sMonth;
  let cYear = sYear;
  let counter = 0;

  while (totalDays > DAYS_IN_MONTH_NON_LEAPYEAR[cMonth]) {
    totalDays -= DAYS_IN_MONTH_NON_LEAPYEAR[cMonth];
    if (cMonth === 2 && isLeapYear(cYear)) {
      totalDays -= 1;
    }

    cMonth++;

    if (cMonth === 12) {
      cMonth = 0;
      cYear++;
    }

    counter++;
  }

  const leftOver = totalDays / DAYS_IN_MONTH_NON_LEAPYEAR[cMonth];

  return counter + leftOver;
}

function getDaysFromMonths(startDate, totalMonths) {
  totalMonths = int(totalMonths);
  const sMonth = startDate.getMonth();
  const sYear = startDate.getFullYear();
  let cMonth = sMonth;
  let cYear = sYear;
  let dayCount = 0;

  for (let i = 0; i < totalMonths; i++) {
    dayCount += DAYS_IN_MONTH_NON_LEAPYEAR[cMonth];
    if (cMonth === 2 && isLeapYear(cYear)) {
      dayCount += 1;
    }

    cMonth++;

    if (cMonth === 12) {
      cMonth = 0;
      cYear++;
    }
  }

  return dayCount;
}

function int(number) {
  return Math.floor(number);
}

export class TimeDiff {
  // Years
  totalYears = 0;
  remainingYears = 0;

  // Months
  totalMonths = 0;
  remainingMonths = 0;

  // Days
  totalDays = 0;
  remainingDays = 0;

  // Hours
  totalHours = 0;
  remainingHours = 0;

  // Minutes
  totalMinutes = 0;
  remainingMinutes = 0;

  // Seconds
  totalSeconds = 0;
  remainingSeconds = 0;

  // Milliseconds
  totalMilliseconds = 0;
  remainingMilliseconds = 0;

  // Time Refs
  endTime = 0;
  nowTime = 0;
  startTime = 0;

  // Percent
  percentComplete = 0;

  constructor(
    nowTimeInMilliseconds,
    endTimeInMilliseconds,
    startTimeInMilliseconds
  ) {
    const nowDate = new Date(nowTimeInMilliseconds);
    const diff = Math.max(0, endTimeInMilliseconds - nowTimeInMilliseconds);

    const totalMilliseconds = diff / MILLISECONDS;
    const totalSeconds = diff / SECONDS_IN_MILLISECONDS;
    const totalMinutes = diff / MINUTES_IN_MILLISECONDS;
    const totalHours = diff / HOURS_IN_MILLISECONDS;
    const totalDays = diff / DAYS_IN_MILLISECONDS;
    const totalMonths = calcTotalMonths(totalDays, nowDate);
    const totalYears = totalMonths / 12;

    this.totalMilliseconds = parseFloat(totalMilliseconds.toPrecision(4));
    this.totalSeconds = parseFloat(totalSeconds.toPrecision(4));
    this.totalMinutes = parseFloat(totalMinutes.toPrecision(4));
    this.totalHours = parseFloat(totalHours.toPrecision(4));
    this.totalDays = parseFloat(totalDays.toPrecision(4));
    this.totalMonths = parseFloat(totalMonths.toPrecision(4));
    this.totalYears = parseFloat(totalYears.toPrecision(4));

    this.remainingYears = int(totalYears);
    this.remainingMonths = int(totalMonths - this.remainingYears * 12);
    this.remainingDays = int(
      totalDays - getDaysFromMonths(nowDate, totalMonths)
    );
    this.remainingHours = int(totalHours - int(totalDays) * 24);
    this.remainingMinutes = int(totalMinutes - int(totalHours) * 60);
    this.remainingSeconds = int(totalSeconds - int(totalMinutes) * 60);
    this.remainingMilliseconds = int(
      totalMilliseconds - int(totalSeconds) * 1000
    );

    this.endTime = endTimeInMilliseconds;
    this.nowTime = nowTimeInMilliseconds;
    this.startTime = startTimeInMilliseconds;

    const startDiff = Math.max(
      0,
      endTimeInMilliseconds - startTimeInMilliseconds
    );
    const calculatedPercentage = (
      ((startDiff - diff) / startDiff) *
      100
    ).toPrecision(4);
    const percentageFloat = parseFloat(calculatedPercentage);
    this.percentComplete = Math.max(Math.min(percentageFloat, 100), 0);
  }

  toString() {
    const timeValues = [];

    if (this.remainingYears) {
      timeValues.push(getPaddedTime(this.remainingYears));
    }

    if (this.remainingYears || this.remainingMonths) {
      timeValues.push(getPaddedTime(this.remainingMonths));
    }

    if (this.remainingYears || this.remainingMonths || this.remainingDays) {
      timeValues.push(getPaddedTime(this.remainingDays));
    }

    timeValues.push(getPaddedTime(this.remainingHours));
    timeValues.push(getPaddedTime(this.remainingMinutes));
    timeValues.push(getPaddedTime(this.remainingSeconds));

    return timeValues.join(":");
  }
}

export function getTimeDiff(nowTime, endTime, startTime) {
  return new TimeDiff(nowTime, endTime, startTime);
}

export function getTimeInSeconds(timeInMilliseconds) {
  return Math.floor(timeInMilliseconds / 1000);
}

export function getTimeInMilliseconds(timeInSeconds) {
  return Math.floor(timeInSeconds * 1000);
}

export function getNow() {
  return Date.now();
}

export function getPaddedTime(time, desiredLength = 2) {
  // console.log("getting time", time);
  return time.toString().padStart(2, "0");
}
