import TimeSequenceItem from "./TimeSequenceItem";

export const SECONDS = 1;
export const MINUTES_IN_SECONDS = SECONDS * 60;
export const HOURS_IN_SECONDS = MINUTES_IN_SECONDS * 60;
export const DAYS_IN_SECONDS = HOURS_IN_SECONDS * 24;
export const YEARS_IN_SECONDS = DAYS_IN_SECONDS * 365; // TODO what about leap year?

export const MILLISECONDS = 1;
export const SECONDS_IN_MILLISECONDS = SECONDS * 1000;
export const MINUTES_IN_MILLISECONDS =
  MINUTES_IN_SECONDS * SECONDS_IN_MILLISECONDS;
export const HOURS_IN_MILLISECONDS = HOURS_IN_SECONDS * SECONDS_IN_MILLISECONDS;
export const DAYS_IN_MILLISECONDS = DAYS_IN_SECONDS * SECONDS_IN_MILLISECONDS;

// Month list
export const DAYS_IN_MONTH_NON_LEAPYEAR = [
  31,
  28,
  31,
  30,
  31,
  30,
  31,
  31,
  30,
  31,
  30,
  31,
];

// Sequences
export const SEQUENCES = new Map();

// TestTest
SEQUENCES.set("testtest", [
  new TimeSequenceItem(5, "Test"),
  new TimeSequenceItem(2, "Test 2"),
  new TimeSequenceItem(4, "Test 3"),
  new TimeSequenceItem(3, "Test 4"),
]);

// Pomodoro
SEQUENCES.set("pomodoro", [
  new TimeSequenceItem(25 * MINUTES_IN_SECONDS, "Work"),
  new TimeSequenceItem(5 * MINUTES_IN_SECONDS, "Rest"),
]);

// Pomodoro
SEQUENCES.set("52-17", [
  new TimeSequenceItem(52 * MINUTES_IN_SECONDS, "Work"),
  new TimeSequenceItem(17 * MINUTES_IN_SECONDS, "Rest"),
]);

// Tabata
SEQUENCES.set("tabata", [
  new TimeSequenceItem(20, "Push It!"),
  new TimeSequenceItem(10, "Rest!"),
  new TimeSequenceItem(20, "Push It!"),
  new TimeSequenceItem(10, "Rest!"),
  new TimeSequenceItem(20, "Push It!"),
  new TimeSequenceItem(10, "Rest!"),
  new TimeSequenceItem(20, "Push It!"),
  new TimeSequenceItem(10, "Rest!"),
  new TimeSequenceItem(20, "Push It!"),
  new TimeSequenceItem(10, "Rest!"),
  new TimeSequenceItem(20, "Push It!"),
  new TimeSequenceItem(10, "Rest!"),
  new TimeSequenceItem(20, "Push It!"),
  new TimeSequenceItem(10, "Rest!"),
  new TimeSequenceItem(20, "Last Push!!"),
  new TimeSequenceItem(10, "Rest!"),
]);

// Morning
SEQUENCES.set("morning", [
  new TimeSequenceItem(6, "Morning warm up, let's go!"),
  new TimeSequenceItem(12, "Get ready to run in place..."),
  new TimeSequenceItem(60, "Run in place!"),
  new TimeSequenceItem(6, "Sweet, get ready for jumping jacks..."),
  new TimeSequenceItem(60, "Jumping Jacks!"),
  new TimeSequenceItem(6, "Good, get ready for sit ups..."),
  new TimeSequenceItem(60, "As many sit ups as you can!"),
  new TimeSequenceItem(6, "Doing great! Here comes pushups..."),
  new TimeSequenceItem(60, "Push Ups!"),
  new TimeSequenceItem(6, "Awesome! Stand back up..."),
  new TimeSequenceItem(60, "Squats!"),
  new TimeSequenceItem(6, "Super! get set for lunges..."),
  new TimeSequenceItem(60, "Lunges!"),
  new TimeSequenceItem(6, "Okay - one more thing.."),
  new TimeSequenceItem(30, "Have a great day!"),
]);

// Pomodoro
SEQUENCES.set("brushteeth", [
  new TimeSequenceItem(2 * MINUTES_IN_SECONDS, "Brush Your Teeth"),
]);

export const COMMON_NAMES = new Map();
COMMON_NAMES.set("lunch", "12:00pm");
