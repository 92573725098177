import React from "react";

function TutorialSection() {
  return (
    <div className="EggTimer-tutorial section">
      <h1>Tutorial</h1>
      <article>
        <iframe width="560" height="315" src="https://www.youtube-nocookie.com/embed/ZDMksgsgBc4?si=7TYcLwtNsKlrY6n8"
                title="Video Player" frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
      </article>
    </div>
  );
}

export default React.memo(TutorialSection);
