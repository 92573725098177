import React, { useCallback, useMemo } from "react";
import ToggleButton from "./ToggleButton";
import { SpeakerHigh, SpeakerSlash } from "phosphor-react";

function MuteToggle({ muted, onToggle }) {
  const toggleMuted = useCallback(() => {
    onToggle(!muted);
  }, [onToggle, muted]);

  const toolTipProperties = useMemo(() => {
    return {
      settings: {
        backgroundColor: "#ff0095",
      },
      "data-for": "mutetoggle",
      "data-tip": "Toggle alert sound",
    };
  }, []);

  return (
    <ToggleButton
      offIcon={<SpeakerSlash />}
      offTitle="Turn off audio"
      on={!muted}
      onIcon={<SpeakerHigh />}
      onTitle="Turn on audio"
      onToggle={toggleMuted}
      tooltipProps={toolTipProperties}
    />
  );
}

export default React.memo(MuteToggle);
