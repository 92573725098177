import "./SettingsAndHelp.css";

import React, { useCallback, useEffect, useRef } from "react";
import { CSSTransition } from "react-transition-group";
import { XCircle } from "phosphor-react";
import HelpSection from "../help/Help";
import TutorialSection from '../help/Tutorial';
import SettingsSection from "../help/Settings";

function SettingsAndHelp({
  isUserNudgePermanentlyDismissed,
  mutedPreference,
  notificationsEnabled,
  notify,
  setIsUserNudgePermanentlyDismissed,
  setMuted,
  setNotify,
  setShowSettings,
  setSound,
  setTheme,
  setVolume,
  showSettings,
  sound,
  theme,
  volume,
}) {
  const settingsRef = useRef();

  const closeSettings = useCallback(() => {
    setShowSettings(false);
  }, [setShowSettings]);

  const clickBackground = useCallback(
    (event) => {
      if (event.target === settingsRef.current) {
        closeSettings();
      }
    },
    [closeSettings]
  );

  useEffect(() => {
    const keypress = (event) => {
      if (event.target.nodeName === "INPUT") return;
      if (event.which === 27) {
        closeSettings();
      }
    };

    document.addEventListener("keydown", keypress);
    return () => {
      document.removeEventListener("keydown", keypress);
    };
  }, [closeSettings]);

  return (
    <>
      <CSSTransition
        classNames="EggTimer-settings-transition"
        in={showSettings}
        key="settings-key"
        nodeRef={settingsRef}
        timeout={300}
        unmountOnExit
      >
        <div
          className="EggTimer-settings-and-help"
          onClick={clickBackground}
          ref={settingsRef}
        >
          <div className="EggTimer-settings-and-help-content">
            <div className="EggTimer-settings-close" onClick={closeSettings}>
              <XCircle />
            </div>
            <TutorialSection />
            <HelpSection onClickLink={closeSettings} />
            <SettingsSection
              isUserNudgePermanentlyDismissed={isUserNudgePermanentlyDismissed}
              mutedPreference={mutedPreference}
              notificationsEnabled={notificationsEnabled}
              notify={notify}
              setIsUserNudgePermanentlyDismissed={
                setIsUserNudgePermanentlyDismissed
              }
              setMuted={setMuted}
              setNotify={setNotify}
              setSound={setSound}
              setTheme={setTheme}
              setVolume={setVolume}
              sound={sound}
              theme={theme}
              volume={volume}
            />
          </div>
        </div>
      </CSSTransition>
    </>
  );
}

export default React.memo(SettingsAndHelp);
