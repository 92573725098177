// Death
import { getNow } from "./TimeDiff";
import {
  getLocalStorageValue,
  setLocalStorageValue,
} from "../util/localStorage";
import { DEATH_TIME } from "../constants";
import { DAYS_IN_MILLISECONDS } from "./constants";

const randomDeathValueMs =
  Math.floor(Math.random() * (DAYS_IN_MILLISECONDS * 351)) + 3;
const randomDeathDate = new Date(getNow() + randomDeathValueMs);
const deathDateString = getLocalStorageValue(DEATH_TIME, null);
let deathDate = new Date(deathDateString);
if (!deathDate || (deathDate && deathDate.getTime() - getNow() <= 0)) {
  deathDate = randomDeathDate;
  setLocalStorageValue(DEATH_TIME, deathDate.toISOString());
}

export const death = deathDate;
