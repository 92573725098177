import "./TimerPage.css";

import React, {
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useParams } from "react-router-dom";

import LabelContext from "../../context/LabelContext";
import TimeContext from "../../context/TimeContext";
import TimerBar from "../timers/common/TimerBar";
import { DEFAULT_PAGE_TITLE, TIMER_PREFERENCE_DEFAULT } from "../../constants";
import {
  getNow,
  getTimeDiff,
  getTimeInMilliseconds,
  TimeDiff,
} from "../../time/TimeDiff";
import { getSequence } from "../../time/getSequence";
import { notifyUser } from "../../notifications/Notifications";
import { setPageTitle } from "../../util/document";
import { getTimer } from "../timers/getTimer";
import UserInteractionNudge from "./UserInteractionNudge";
import Sheldon from "../svgs/Sheldon";
import Sound from "../timers/common/Sound";
import { SECONDS_IN_MILLISECONDS } from "../../time/constants";

function TimerPage({
  hasInteracted = false,
  isUserNudgePermanentlyDismissed = false,
  muted = false,
  mutedPreference = false,
  notify = true,
  notifyPrompt = false,
  setIsUserNudgePermanentlyDismissed,
  setMuted,
  setNotify,
  setNotifyPrompt,
  setShowSettings,
  setTheme,
  setToolbarCollapsed,
  soundPreference,
  theme = TIMER_PREFERENCE_DEFAULT,
  toolbarCollapsed,
  volume,
}) {
  // Time
  const { time: timeParam, label: labelParam, [0]: wildcardParam } = useParams();
  const [label, setLabel] = useState(labelParam);
  const [timeDiff, setTimeDiff] = useState(new TimeDiff(-1, -1));
  const [timeSequenceIndex, setTimeSequenceIndex] = useState(0);
  const [timeSequence, setTimeSequence] = useState([]);

  // Decode the value, if it is a number and doesn't have a unit, set seconds.
  const timeValue = timeParam ?? wildcardParam;
  const timerValue = decodeURIComponent(timeValue);

  // Resets the timer back to first sequence.
  const resetTimer = useCallback(() => {
    if (timeSequenceIndex === 0) {
      const sequence = getSequence(timerValue, label);
      setTimeSequence(sequence);
      return;
    }
    setTimeSequenceIndex(0);
  }, [
    timerValue,
    label,
    timeSequenceIndex,
    setTimeSequenceIndex,
    setTimeSequence,
  ]);

  // Get the timer sequence
  useMemo(() => {
    const sequence = getSequence(timerValue, label);
    setTimeSequence(sequence);
  }, [timerValue, label]);

  // Audio Element
  const audioEl = useRef();

  // THE TIMER IN ACTION
  useEffect(() => {
    let interval;
    let currentSecond = Math.floor(Date.now() / 1000);
    let finalImmediate;

    if (timeSequence.length > 0) {
      // Get the next time sequence item
      const timeSequenceItem = timeSequence[timeSequenceIndex];

      if (timeSequenceItem) {
        const startTimeInMilliseconds = getNow();
        const endTimeInMilliseconds =
          startTimeInMilliseconds +
          getTimeInMilliseconds(timeSequenceItem.seconds);
        const nextTimeDiff = getTimeDiff(
          startTimeInMilliseconds,
          endTimeInMilliseconds,
          startTimeInMilliseconds
        );
        setLabel(timeSequenceItem.label);
        setTimeDiff(nextTimeDiff);

        const updateTime = () => {
          const nextTimeDiff = getTimeDiff(
            getNow(),
            endTimeInMilliseconds,
            startTimeInMilliseconds
          );

          setTimeDiff(nextTimeDiff);

          if (nextTimeDiff.endTime > 0 && nextTimeDiff.totalSeconds <= 0) {
            // If there is another sequence, set the item otherwise, stop the timer.
            let labelSlug = label || "";
            let expiredSlug = "Time Expired!";
            let shouldUseFallback = true;
            if (timeSequenceIndex < timeSequence.length - 1) {
              // Notify the user but no fallback (alert)
              labelSlug = label || "Segment";
              expiredSlug = "";
              shouldUseFallback = false;
            }

            finalImmediate = setImmediate(() => {
              audioEl.current
                .play()
                .catch((err) => console.warn(err))
                .finally(() => {
                  notifyUser(
                    `${labelSlug} ${expiredSlug}`.trim(),
                    shouldUseFallback
                  );
                });

              // Set next sequence item
              setTimeSequenceIndex(timeSequenceIndex + 1);
            });
          }
        };

        const checkTime = () => {
          const nowSecond = Math.floor(Date.now() / 1000);
          if (nowSecond === currentSecond) {
            return;
          }

          currentSecond = nowSecond;
          updateTime();
        };

        // The actual timing interval
        // TODO move this to a worker?
        // Use a generator?
        interval = setInterval(checkTime, SECONDS_IN_MILLISECONDS / 24);
      }
    }

    return () => {
      clearInterval(interval);
      clearImmediate(finalImmediate);
      setPageTitle(DEFAULT_PAGE_TITLE);
    };
  }, [audioEl, timerValue, label, timeSequence, timeSequenceIndex]);

  // Update the page title when the timer updates
  useEffect(() => {
    let labelSlug = label ? `${label} : ` : "";
    if (timeDiff.totalSeconds <= 0) {
      setPageTitle(`${labelSlug}time expired!`);
      return;
    }

    setPageTitle(`${labelSlug}${timeDiff.toString()}`);
  }, [timeDiff, timeSequenceIndex, timeSequence, label]);

  // If there was an error parsing the time, show this on screen
  useEffect(() => {
    if (timeSequence.length > 0) return;
    setLabel("Could not parse time");
  }, [timeSequence]);

  // Fallback UI for while loading timer async
  const fallback = useMemo(() => {
    // TODO better transition here?
    return (
      <Sheldon
        alt="Loading graphic, in this case the egg timer logo."
        className="EggTimer-load-icon"
        height="228"
        width="228"
      />
    );
  }, []);

  return (
    <main className="EggTimer-timer">
      <LabelContext.Provider value={label}>
        <TimeContext.Provider value={timeDiff}>
          <div className="EggTimer-timer-wrapper">
            <Suspense fallback={fallback}>{getTimer(theme)}</Suspense>
          </div>
        </TimeContext.Provider>
      </LabelContext.Provider>
      <UserInteractionNudge
        hasInteracted={hasInteracted}
        isPermanentlyDismissed={isUserNudgePermanentlyDismissed}
        key="user nudge"
        mutedPreference={mutedPreference}
        setIsPermanentlyDismissed={setIsUserNudgePermanentlyDismissed}
      />
      <TimeContext.Provider value={timeDiff}>
        <TimerBar
          collapsed={toolbarCollapsed}
          muted={muted}
          notify={notify}
          notifyPrompt={notifyPrompt}
          resetTime={resetTimer}
          setCollapsed={setToolbarCollapsed}
          setMuted={setMuted}
          setNotify={setNotify}
          setNotifyPrompt={setNotifyPrompt}
          setShowSettings={setShowSettings}
          setTheme={setTheme}
          theme={theme}
        />
        <Sound
          muted={muted}
          soundPreference={soundPreference}
          soundRef={audioEl}
          volume={volume}
        />
      </TimeContext.Provider>
    </main>
  );
}

export default React.memo(TimerPage);
