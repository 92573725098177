export default class TimeSequenceItem {
  constructor(seconds, label = null) {
    this._seconds = seconds;
    this._label = label;
  }

  get seconds() {
    return this._seconds;
  }

  set seconds(value) {
    this._seconds = value;
  }

  get label() {
    return this._label;
  }

  set label(value) {
    this._label = value;
  }
}
