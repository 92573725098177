let notification = undefined;
let userNotificationPreference = true;

export const getSupportsNotifications = () => {
  return "Notification" in self; // eslint-disable-line no-restricted-globals
};

export const getUserNotificationPreference = () => userNotificationPreference;

export const setUserNotificationPreference = (preference) => {
  userNotificationPreference = preference;
};

export const getNotificationsEnabled = () => {
  return getSupportsNotifications() && Notification.permission === "granted";
};

export const getCanRequestNotifications = () => {
  return (
    getSupportsNotifications() && // Browser supports notifications
    !getNotificationsEnabled() && // User hasn't already granted permission
    Notification.permission !== "denied"
  ); // User hasn't already denied permission
};

export const closeNotification = () => {
  if (!notification) return;
  notification.close();
};

document.addEventListener("visibilitychange", function () {
  if (document.visibilityState === "visible") {
    // The tab has become visible so clear the now-stale Notification.
    closeNotification();
  }
});

export const requestNotifications = () => {
  let callbackCalled = false;
  const requestCallback = (permission) => {
    if (callbackCalled) return;
    callbackCalled = true;
  };

  if (getNotificationsEnabled()) {
    requestCallback(true);
  }

  const requestPromise = Notification.requestPermission(requestCallback);
  if (requestPromise && typeof requestPromise.then === "function") {
    requestPromise.then(requestCallback);
  }
};

export const notifyUser = (message, useFallback = true) => {
  if (!getUserNotificationPreference()) return;

  if (!getNotificationsEnabled() && useFallback) {
    // Fallback to alert
    return alert(message);
  }

  if (notification) {
    notification.close();
  }

  notification = new Notification(message, {
    icon: "/logo192.png",
    requireInteraction: true,
  });

  notification.onclick = function (x) {
    window.focus();
    this.close();
  };

  return notification;
};
