import React, { useContext, useEffect, useMemo, useRef } from "react";
import { SOUND_PREFERENCE_VALUES } from "../../../constants";
import TimeContext from "../../../context/TimeContext";
import { getSoundFile } from "./getSound";

function Sound({ muted, soundPreference, soundRef, volume }) {
  const timeDiff = useContext(TimeContext);
  const { remainingSeconds, remainingHours } = timeDiff;

  useEffect(() => {
    soundRef.current.volume = volume;
  }, [soundRef, volume]);

  // Hack for Gene
  const is24 = soundPreference === SOUND_PREFERENCE_VALUES["1440"];
  const ref24Airhorn = useRef();
  const ref24Tick = useRef();
  const ref24Tock = useRef();

  useEffect(() => {
    if (!is24) return;
    const soundRef = remainingSeconds % 2 ? ref24Tock : ref24Tick;
    setImmediate(() => {
      soundRef.current.play().catch((error) => {
        console.warn(error);
      });
    });
  }, [is24, remainingSeconds]);

  useEffect(() => {
    if (!is24) return;
    const immediate = setImmediate(() => {
      ref24Airhorn.current.play().catch((error) => {
        console.warn(error);
      });
    });

    return () => {
      clearImmediate(immediate);
    };
  }, [is24, remainingHours]);

  // End Hack for Gene

  const soundFile = useMemo(() => {
    return getSoundFile(soundPreference);
  }, [soundPreference]);

  return (
    <>
      <audio key={soundFile} ref={soundRef} preload="auto" muted={muted}>
        <source
          src={`${process.env.PUBLIC_URL}/${soundFile}.mp3`}
          type="audio/mpeg"
        />
        <source
          src={`${process.env.PUBLIC_URL}/${soundFile}.ogg`}
          type="audio/ogg"
        />
      </audio>
      {is24 && (
        <>
          <audio ref={ref24Tick} preload="auto" muted={muted}>
            <source
              src={`${process.env.PUBLIC_URL}/Tick1440.mp3`}
              type="audio/mpeg"
            />
          </audio>
          <audio ref={ref24Tock} preload="auto" muted={muted}>
            <source
              src={`${process.env.PUBLIC_URL}/Tock1440.mp3`}
              type="audio/mpeg"
            />
          </audio>
          <audio ref={ref24Airhorn} preload="auto" muted={muted}>
            <source
              src={`${process.env.PUBLIC_URL}/tripleairhorn.mp3`}
              type="audio/mpeg"
            />
          </audio>
        </>
      )}
    </>
  );
}

export default React.memo(Sound);
