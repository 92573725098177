import "./StartPage.css";

import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Link, withRouter } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import classnames from "classnames";
import { getSequence } from "../../time/getSequence";
import Sheldon from "../svgs/Sheldon";

function StartPage({
  history,
  newVersionAvailable,
  setShowSettings,
  updateVersion,
}) {
  const [timeInputValue, setTimeInputValue] = useState("");
  const [isValidTime, setIsValidTime] = useState(false);

  const onShowSettings = useCallback(() => {
    setShowSettings(true);
  }, [setShowSettings]);

  useEffect(() => {
    const sequence = getSequence(timeInputValue);
    setIsValidTime(sequence.length > 0);
  }, [timeInputValue]);

  const onStartTime = useCallback(
    (timeValue) => {
      if (!isValidTime) return;
      history.push(`/${timeValue.toLowerCase()}`);
    },
    [history, isValidTime]
  );

  const handleStartButtonClick = useCallback(() => {
    onStartTime(timeInputValue);
  }, [onStartTime, timeInputValue]);

  const handleTimeInputChange = useCallback(
    (e) => {
      setTimeInputValue(e.target.value);
    },
    [setTimeInputValue]
  );

  const handleTimeInputKeyUp = useCallback(
    (e) => {
      if (e.which === 13 /* Enter */) {
        onStartTime(timeInputValue);
      }
    },
    [onStartTime, timeInputValue]
  );

  const inputRef = useRef();
  useEffect(() => {
    inputRef.current.focus();
  }, [inputRef]);

  const handleInputFocus = useCallback(
    (e) => {
      inputRef.current.select();
    },
    [inputRef]
  );

  const [startButtonClass, startButtonTooltip] = useMemo(() => {
    const className = classnames({
      validTime: isValidTime,
    });

    const tooltip = isValidTime
      ? ""
      : 'Please enter a valid time. Try "15 minutes" or "9:00pm"';

    return [className, tooltip];
  }, [isValidTime]);

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [newVersionAvailable]);

  return (
    <div className="EggTimer-start">
      <div className="EggTimer-start-flourish" />
      <div className="EggTimer-start-content">
        <Sheldon
          alt="EggTimer Logo, depicting an egg wearing a clock necklace"
          className="EggTimer-start-icon"
          height="228"
          width="228"
        />
        <main className="EggTimer-start-main">
          <div className="EggTimer-start-time-input">
            <div className="EggTimer-start-time-input-wrapper">
              <label
                className="EggTimer-start-time-input-label"
                htmlFor="EggTimer-start-time-input-text"
              >
                /
              </label>
              <input
                className="EggTimer-start-time-input-text"
                id="EggTimer-start-time-input-text"
                onChange={handleTimeInputChange}
                onFocus={handleInputFocus}
                onKeyUp={handleTimeInputKeyUp}
                placeholder="Enter a time"
                ref={inputRef}
                type="text"
                value={timeInputValue}
              />
            </div>
            <button
              className={startButtonClass}
              data-for="startpage"
              data-tip={startButtonTooltip}
              onClick={handleStartButtonClick}
            >
              Start
            </button>
          </div>
          <div className="EggTimer-start-welcome">
            <p>E.ggTimer is a simple countdown timer.</p>
            <p>Try one of these common timers</p>
          </div>
          <div className="EggTimer-start-quick-time">
            <Link
              data-for="startpage"
              data-tip="https://e.ggtimer.com/5minutes"
              title="5 minutes"
              to={process.env.PUBLIC_URL + "/5minutes"}
            >
              /5 minutes
            </Link>
            <Link
              data-for="startpage"
              data-tip="https://e.ggtimer.com/10minutes"
              title="10 minutes"
              to={process.env.PUBLIC_URL + "/10minutes"}
            >
              /10 minutes
            </Link>
            <Link
              data-for="startpage"
              data-tip="https://e.ggtimer.com/15minutes"
              title="15 minutes"
              to={process.env.PUBLIC_URL + "/15minutes"}
            >
              /15 minutes
            </Link>
          </div>
          <div className="EggTimer-start-quick-time">
            <Link
              data-for="startpage"
              data-tip="https://e.ggtimer.com/30minutes"
              title="30 minutes"
              to={process.env.PUBLIC_URL + "/30minutes"}
            >
              /30 minutes
            </Link>
            <Link
              data-for="startpage"
              data-tip="25 minutes of work followed by 5 minutes of rest"
              title="Pomodoro"
              to={process.env.PUBLIC_URL + "/pomodoro"}
            >
              /Pomodoro
            </Link>
            <Link
              data-for="startpage"
              data-tip="52 minutes of work followed by 17 minutes of rest"
              title="52-17"
              to={process.env.PUBLIC_URL + "/52-17"}
            >
              /52-17
            </Link>
          </div>
        </main>
      </div>
      <div className="EggTimer-start-colophon">
        Feedback or questions? Contact&nbsp;
        <Link
          to={"#"}
          onClick={(e) => {
            window.location.href = "mailto:support@e.ggtimer.com";
            e.preventDefault();
          }}
        >
          support<span style={{ display: "none" }}>botsnotallowed</span>
          @e.ggtimer.com
        </Link>
      </div>
      <div className="EggTimer-start-meta">
        <button
          data-for="startpage"
          data-tip="Help and Settings"
          onClick={onShowSettings}
        >
          Help and Settings
        </button>
        {newVersionAvailable && (
          <button
            data-for="startpage"
            data-tip="There is a new version of E.ggTimer available."
            onClick={updateVersion}
          >
            Update E.ggTimer
          </button>
        )}
      </div>
      <ReactTooltip
        delayShow={120}
        effect="solid"
        id="startpage"
        place="top"
        type="light"
      />
    </div>
  );
}

export default withRouter(React.memo(StartPage));
