import TimeSequenceItem from "./TimeSequenceItem";
import stringToTime from "locutus/php/datetime/strtotime";
import { getNow, getTimeInSeconds } from "./TimeDiff";
import {
  COMMON_NAMES,
  DAYS_IN_SECONDS,
  SEQUENCES,
  YEARS_IN_SECONDS,
} from "./constants";
import { getHolidayTime } from "./holidays";

const hasSequence = (timerValue) => {
  return timerValue && SEQUENCES.has(timerValue.toLowerCase());
};

const isCommonName = (timerValue) => {
  return timerValue && COMMON_NAMES.has(timerValue.toLowerCase());
};

export const getSequence = (timerValue, label = null) => {
  const sequenceSet = [];
  if (!timerValue) return sequenceSet;

  // Check if there is a named sequence
  if (hasSequence(timerValue)) {
    sequenceSet.push(...SEQUENCES.get(timerValue.toLowerCase()));
    return sequenceSet
  }

  const nowInSeconds = getTimeInSeconds(getNow());
  const times = timerValue.split('/');

  times.forEach((timeValue) => {
    if (isCommonName(timeValue)) {
      timeValue = COMMON_NAMES.get(timeValue.toLowerCase());
    }

    let endTimeInSeconds = stringToTime(timeValue, nowInSeconds);

    // Look for holidays
    if (endTimeInSeconds === false) {
      const holidayMatch = getHolidayTime(timeValue);
      if (holidayMatch) {
        const [holidayDate, holidayLabel] = holidayMatch;
        if (!label) {
          label = holidayLabel;
        }
        endTimeInSeconds = stringToTime(holidayDate);
      }
    }

    // If the time wasn't parsable, try adding default units or values
    if (endTimeInSeconds === false) {
      // Fix some odd formatting
      if (!isNaN(timeValue)) {
        endTimeInSeconds = stringToTime(`${timeValue} minutes`, nowInSeconds);
      } else if (isNaN(parseInt(timeValue, 10))) {
        endTimeInSeconds = stringToTime(`1 ${timeValue}`, nowInSeconds);
      }
    }

    // If the time is still invalid, try looking for format like 5m, etc.
    if (endTimeInSeconds === false) {
      // Try one last thing with regex.
      const timeMatch = timeValue.match(/(\d+hr?)?\s?(\d+m)?\s?(\d+s)?/);
      if (timeMatch && timeMatch[0]) {
        const timerParts = [];
        let [, hours, minutes, seconds] = timeMatch;

        if (hours) {
          timerParts.push(`${parseInt(hours, 10)} hours`);
        }
        if (minutes) {
          timerParts.push(`${parseInt(minutes, 10)} minutes`);
        }
        if (seconds) {
          timerParts.push(`${parseInt(seconds, 10)} seconds`);
        }

        endTimeInSeconds = stringToTime(timerParts.join(" "), nowInSeconds);
      }
    }

    // If time is valid, set up a default sequence,
    if (endTimeInSeconds !== false) {
      // If the time is value, but in the past, try to adjust.
      if (endTimeInSeconds <= nowInSeconds) {
        if (nowInSeconds - endTimeInSeconds < DAYS_IN_SECONDS) {
          // Add a day
          endTimeInSeconds += DAYS_IN_SECONDS;
        } else {
          // Add a year (Assuming it's a specific date)
          endTimeInSeconds += YEARS_IN_SECONDS;
        }
      }
      sequenceSet.push(
        new TimeSequenceItem(endTimeInSeconds - nowInSeconds, label)
      );
    }
  });

  return sequenceSet;
};
