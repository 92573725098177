import React, { useCallback, useMemo } from "react";
import ToggleButton from "./ToggleButton";
import {
  getCanRequestNotifications,
  requestNotifications,
} from "../../notifications/Notifications";
import { Bell, BellSlash } from "phosphor-react";

function NotificationToggle({ notify, onToggle, promptUser, onUserPrompted }) {
  const toggleNotify = useCallback(() => {
    onToggle(!notify);
  }, [onToggle, notify]);

  const onPromptForNotifications = useCallback(() => {
    if (getCanRequestNotifications()) {
      requestNotifications();
      onUserPrompted();
    }
  }, [onUserPrompted]);

  const extraTipValue = useMemo(() => {
    if (!promptUser) return null;

    return (
      <button
        className="EggTimer-notify-prompt"
        onClick={onPromptForNotifications}
      >
        <Bell /> Enable Notifications
      </button>
    );
  }, [promptUser, onPromptForNotifications]);

  const tooltip = useMemo(() => {
    return promptUser ? "" : "Toggle notifications";
  }, [promptUser]);

  const toolTipProps = useMemo(() => {
    return {
      settings: {
        backgroundColor: promptUser ? "#111111" : "#ff0095",
        border: promptUser,
        delayHide: promptUser ? 1000 : 0,
        delayShow: promptUser ? 1000 : 120,
      },
      "data-for": promptUser ? "notifyprompt" : "notifytoggle",
      "data-tip": tooltip,
      show: promptUser,
      extraTipValue: extraTipValue,
    };
  }, [tooltip, promptUser, extraTipValue]);

  return (
    <ToggleButton
      offIcon={<BellSlash />}
      offTitle="Turn off notifications"
      on={notify}
      onIcon={<Bell />}
      onTitle="Turn on notifications"
      onToggle={toggleNotify}
      tooltipProps={toolTipProps}
    />
  );
}

export default React.memo(NotificationToggle);
