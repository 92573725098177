import { getQueryParam, setQueryParam } from "../queryParams";
import { useLocalStorage } from "./useLocalStorage";

export function useUserPreference(preference, queryParam, defaultValue) {
  const queryStringPref = getQueryParam(queryParam);

  const [localStoragePref, setLocalStoragePref] = useLocalStorage(
    preference,
    defaultValue,
    queryStringPref
  );

  // Prefer query param if it is set.
  const userPref = localStoragePref;

  // Make the assumption that setting the pref means a local override and remove the query param.
  const setUserPreference = (value) => {
    const valueToStore = value instanceof Function ? value(userPref) : value;

    // Clear query param
    setQueryParam(queryParam, undefined);
    setLocalStoragePref(valueToStore);
  };

  return [userPref, setUserPreference];
}

export function useBooleanUserPreference(preference, queryParam, defaultValue) {
  const [userPref, setUserPref] = useUserPreference(
    preference,
    queryParam,
    defaultValue
  );
  return [userPref.toString().toLowerCase() === "true", setUserPref];
}

export function useNumberUserPreference(preference, queryParam, defaultValue) {
  const [userPref, setUserPref] = useUserPreference(
    preference,
    queryParam,
    defaultValue
  );
  return [parseFloat(userPref), setUserPref];
}
