import {
  SOUND_PREFERENCE_DEFAULT,
  SOUND_PREFERENCE_VALUES,
} from "../../../constants";

export const getSoundFile = (soundPreference = SOUND_PREFERENCE_DEFAULT) => {
  if (soundPreference === SOUND_PREFERENCE_VALUES.RingRing) return "ringring";
  return "beepbeep";
};

export const SOUND_LABELS = new Map([
  [SOUND_PREFERENCE_VALUES.RingRing, "Ring"],
  [SOUND_PREFERENCE_VALUES.BeepBeep, "Beep"],
]);

export const getSoundLabel = (soundPreference = SOUND_PREFERENCE_DEFAULT) => {
  return (
    SOUND_LABELS.get(soundPreference) ||
    SOUND_LABELS.get(SOUND_PREFERENCE_DEFAULT)
  );
};
