import "./App.css";

import React, { useCallback, useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import TimerPage from "./components/pages/TimerPage";
import StartPage from "./components/pages/StartPage";
import {
  useBooleanUserPreference,
  useUserPreference,
} from "./util/hooks/useUserPreference";
import {
  getCanRequestNotifications,
  getNotificationsEnabled,
  setUserNotificationPreference,
} from "./notifications/Notifications";
import {
  MUTED_PREFERENCE,
  MUTED_QUERY_PARAM,
  NOTIFY_PREFERENCE,
  NOTIFY_QUERY_PARAM,
  SOUND_PREFERENCE,
  SOUND_PREFERENCE_DEFAULT,
  SOUND_QUERY_PARAM,
  TIMER_PREFERENCE_DEFAULT,
  TIMER_PREFERENCE_KEY,
  TIMER_QUERY_PARAM,
  TOOLBAR_QUERY_PARAM,
  TOOLBAR_TOGGLE_PREFERENCE,
  USER_NUDGE_DISABLED_PREFERENCE,
  USER_NUDGE_DISABLED_QUERY_PARAM,
  VOLUME_PREFERENCE,
  VOLUME_QUERY_PARAM,
} from "./constants";
import { SECONDS_IN_MILLISECONDS } from "./time/constants";
import SettingsAndNews from "./components/pages/SettingsAndHelp";
import * as serviceWorker from "./registerServiceWorker";

function App() {
  // Current App Version
  const [newVersionAvailable, setNewVersionAvailable] = useState(false);
  const [waitingWorker, setWaitingWorker] = useState(null);

  useEffect(() => {
    // If you want your app to work offline and load faster, you can change
    // unregister() to register() below. Note this comes with some pitfalls.
    // Learn more about service workers: https://bit.ly/CRA-PWA
    serviceWorker.register({
      onUpdate: (registration) => {
        if (!registration) return;
        setWaitingWorker(registration.waiting);
        setNewVersionAvailable(true);
      },
    });
  }, [setNewVersionAvailable, setWaitingWorker]);

  const updateVersion = useCallback(() => {
    if (!newVersionAvailable || !waitingWorker) return;

    // Tell the worker to skip waiting
    waitingWorker.postMessage({ type: "SKIP_WAITING" });
    setNewVersionAvailable(false);

    // Reload the page.
    window.location.reload();
  }, [newVersionAvailable, waitingWorker, setNewVersionAvailable]);

  // Interaction listener for enabling sound
  const [hasInteracted, setHasInteracted] = useState(false);
  const handleMouse = useCallback(() => {
    if (hasInteracted) return;
    setHasInteracted(true);
  }, [hasInteracted, setHasInteracted]);

  useEffect(() => {
    if (hasInteracted) return;

    function removeHandler() {
      document.removeEventListener("keypress", handleDocumentInteraction);
    }

    function handleDocumentInteraction() {
      setHasInteracted(true);
      removeHandler();
    }

    document.addEventListener("keypress", handleDocumentInteraction);
    return removeHandler;
  }, [hasInteracted, setHasInteracted]);

  // User Nudge Preference
  const [
    isUserNudgePermanentlyDismissed,
    setIsUserNudgePermanentlyDismissed,
  ] = useBooleanUserPreference(
    USER_NUDGE_DISABLED_PREFERENCE,
    USER_NUDGE_DISABLED_QUERY_PARAM,
    false
  );

  // Theme
  const [themePreference, setThemePreference] = useUserPreference(
    TIMER_PREFERENCE_KEY,
    TIMER_QUERY_PARAM,
    TIMER_PREFERENCE_DEFAULT
  );
  const updateTheme = useCallback(
    (theme) => {
      setThemePreference(theme);
    },
    [setThemePreference]
  );

  // Audio
  const [mutedPreference, setMutedPreference] = useBooleanUserPreference(
    MUTED_PREFERENCE,
    MUTED_QUERY_PARAM,
    false
  );
  const [volumePreference, setVolumePreference] = useUserPreference(
    VOLUME_PREFERENCE,
    VOLUME_QUERY_PARAM,
    1
  );
  const muted = !hasInteracted || mutedPreference || volumePreference === 0;

  // Sound
  const [soundPreference, setSoundPreference] = useUserPreference(
    SOUND_PREFERENCE,
    SOUND_QUERY_PARAM,
    SOUND_PREFERENCE_DEFAULT
  );

  const updateSound = useCallback(
    (sound) => {
      setSoundPreference(sound);
    },
    [setSoundPreference]
  );

  // Notifications
  const [notifyPreference, setNotifyPreference] = useBooleanUserPreference(
    NOTIFY_PREFERENCE,
    NOTIFY_QUERY_PARAM,
    true
  );
  const notificationsEnabled = getNotificationsEnabled();
  const canRequestNotify = getCanRequestNotifications();
  const [promptForNotifications, setPromptForNotifications] = useState(
    notifyPreference && !notificationsEnabled && canRequestNotify
  );

  // User notification prompt timer.
  useEffect(() => {
    if (!promptForNotifications) return;

    const promptTimeout = setTimeout(() => {
      setPromptForNotifications(false);
    }, SECONDS_IN_MILLISECONDS * 24);

    return () => {
      clearTimeout(promptTimeout);
    };
  }, [promptForNotifications, setPromptForNotifications]);

  // If the user accepts or rejects prompt, no need to show it anymore.
  useEffect(() => {
    const shouldPrompt = !notificationsEnabled && canRequestNotify;
    if (promptForNotifications && !shouldPrompt) {
      setPromptForNotifications(false);
    }
  }, [promptForNotifications, notificationsEnabled, canRequestNotify]);

  // Set global value when user updates local value
  useEffect(() => {
    setUserNotificationPreference(notifyPreference);
  }, [notifyPreference]);

  // Settings/News Menu
  const [showSettings, setShowSettings] = useState(false);

  const updateShowSettings = useCallback(
    (show) => {
      setShowSettings(show);
    },
    [setShowSettings]
  );

  useEffect(() => {
    const keypress = (event) => {
      if (event.target.nodeName === "INPUT") return;
      if (event.which === 191) {
        updateShowSettings(!showSettings);
      }
    };

    document.addEventListener("keydown", keypress);
    return () => {
      document.removeEventListener("keydown", keypress);
    };
  }, [showSettings, updateShowSettings]);

  const [toolbarCollapsed, setToolbarCollapsed] = useBooleanUserPreference(
    TOOLBAR_TOGGLE_PREFERENCE,
    TOOLBAR_QUERY_PARAM,
    false
  );

  return (
    <div className="EggTimer" onClick={handleMouse}>
      <Router>
        <Switch>
          <Redirect from="/v4/:time?" to="/:time?" />
          <Route exact path={process.env.PUBLIC_URL + "/"}>
            <StartPage
              newVersionAvailable={newVersionAvailable}
              setShowSettings={updateShowSettings}
              updateVersion={updateVersion}
            />
          </Route>
          <Route
            path={[
              process.env.PUBLIC_URL + "/:label/(in|for|at|on)/:time",
              process.env.PUBLIC_URL + "/:time/until/:label",
              process.env.PUBLIC_URL + "/*",
            ]}
          >
            <TimerPage
              hasInteracted={hasInteracted}
              isUserNudgePermanentlyDismissed={isUserNudgePermanentlyDismissed}
              muted={muted}
              mutedPreference={mutedPreference}
              notify={notifyPreference}
              notifyPrompt={promptForNotifications}
              setIsUserNudgePermanentlyDismissed={
                setIsUserNudgePermanentlyDismissed
              }
              setMuted={setMutedPreference}
              setNotify={setNotifyPreference}
              setNotifyPrompt={setPromptForNotifications}
              setShowSettings={updateShowSettings}
              setTheme={updateTheme}
              setToolbarCollapsed={setToolbarCollapsed}
              setVolume={setVolumePreference}
              soundPreference={soundPreference}
              theme={themePreference}
              toolbarCollapsed={toolbarCollapsed}
              volume={volumePreference}
            />
          </Route>
        </Switch>
        <SettingsAndNews
          isUserNudgePermanentlyDismissed={isUserNudgePermanentlyDismissed}
          mutedPreference={mutedPreference}
          notificationsEnabled={notificationsEnabled}
          notify={notifyPreference}
          setIsUserNudgePermanentlyDismissed={
            setIsUserNudgePermanentlyDismissed
          }
          setMuted={setMutedPreference}
          setNotify={setNotifyPreference}
          setShowSettings={updateShowSettings}
          setSound={updateSound}
          setTheme={updateTheme}
          setVolume={setVolumePreference}
          showSettings={showSettings}
          sound={soundPreference}
          theme={themePreference}
          volume={volumePreference}
        />
      </Router>
    </div>
  );
}

export default React.memo(App);
