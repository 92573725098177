import Holidays from "date-holidays";
import Fuzzy from "fuzzyset";
import { getNow } from "./TimeDiff";
import { death } from "./death";
import { strftime } from "locutus/php/datetime";
import getUserLang from "../util/ language";

// public	public holiday
// bank	bank holiday, banks and offices are closed
// school	school holiday, schools are closed
// optional	majority of people take a day off
// observance
const HOLIDAY_TYPE_SCORES = {
  public: 5000,
  bank: 4000,
  school: 3000,
  optional: 2000,
  observance: 1000,
};

const holidayOptions = { languages: [getUserLang(), "en"] };
const usaHolidays = new Holidays("US", holidayOptions);
const canadaHolidays = new Holidays("CA", holidayOptions);
const greatBritainHolidays = new Holidays("GB", holidayOptions);
const spainHolidays = new Holidays("ES", holidayOptions);
const emiratesHolidays = new Holidays("AE", holidayOptions);

// Add Easter
usaHolidays.setHoliday("easter", {
  name: "Easter",
  type: "bank",
});

// Add Yom Kippur
usaHolidays.setHoliday("10 Tishrei", {
  name: "Yom Kippur",
  type: "public",
});

// Add Hanukkah
usaHolidays.setHoliday("25 Kislev", {
  name: "Hanukkah",
  type: "school",
});

// Add Juneteenth
usaHolidays.setHoliday("06-19", {
  name: "Juneteenth",
  type: "observance",
});

// Add Kwanzaa
usaHolidays.setHoliday("12-26", {
  name: "Kwanzaa",
  type: "school",
});

// Add Death
const deathRule = strftime(`%Y-%m-%d`, death);
usaHolidays.setHoliday(deathRule, {
  name: "Death",
  type: "school",
});

// Set up holiday lists
const holidays = new Set([
  usaHolidays,
  canadaHolidays,
  greatBritainHolidays,
  spainHolidays,
  emiratesHolidays,
]);

// Create a fuzzy match set
const holidayFuzzyMatch = new Fuzzy();

// Get this year
const year = new Date(getNow()).getFullYear();

// A map to hold name => holiday date
const holidayMap = new Map();

// Go through each list
holidays.forEach((holidayObj) => {
  const holidayList = holidayObj.getHolidays(year);
  holidayList.push(...holidayObj.getHolidays(year + 1));
  holidayList.forEach((holiday) => {
    const { date, name } = holiday;
    const holidayDate = new Date(date);

    // If it already happened ignore it.
    if (holidayDate.getTime() < getNow()) return;

    // To get a unique list, no repetition by year.
    if (!holidayMap.has(name)) {
      // Add to the fuzzy match set.
      holidayFuzzyMatch.add(name);

      const holidayValue = {
        holiday,
        languages: holidayObj.getLanguages(),
      };

      holidayMap.set(name, holidayValue);
    }
  });
});

export const getHolidayTime = (timerValue, userLang = getUserLang()) => {
  let holidayMatches = [];
  const fuzzyHolidayMatches = holidayFuzzyMatch.get(timerValue, null, 0.6);
  if (fuzzyHolidayMatches) {
    holidayMatches = fuzzyHolidayMatches;
  } else if (timerValue.length > 3) {
    holidayMatches = Array.from(holidayMap.keys()).reduce(
      (accumulator, holidayKey) => {
        if (holidayKey.toLowerCase().includes(timerValue.toLowerCase())) {
          // Push arbitrary score based on string lengths
          const lengthScore = timerValue.length / holidayKey.length;
          if (lengthScore > 0.3) {
            accumulator.push([lengthScore, holidayKey]);
          }
        }

        return accumulator;
      },
      []
    );
  }

  if (!holidayMatches || holidayMatches.length === 0) return null;

  // Check to see if there is a tie in match scores.
  let matchScore = null;
  const matchTie =
    holidayMatches.length > 1 &&
    holidayMatches.every(([score]) => {
      if (matchScore === null) {
        matchScore = score;
        return true;
      }

      return score === matchScore;
    });

  // if there is a tie, sort by type
  if (matchTie) {
    holidayMatches.sort((a, b) => {
      const [, holidayNameA] = a;
      const [, holidayNameB] = b;

      const { holiday: holidayA } = holidayMap.get(holidayNameA);
      const { holiday: holidayB } = holidayMap.get(holidayNameB);

      const { date: dateStringA, type: typeA } = holidayA;
      const { date: dateStringB, type: typeB } = holidayB;

      const holidayATypeScore = HOLIDAY_TYPE_SCORES[typeA] || 0;
      const holidayBTypeScore = HOLIDAY_TYPE_SCORES[typeB] || 0;

      const dateA = new Date(dateStringA);
      const dateB = new Date(dateStringB);

      // If matching prominence, then use closest date.
      if (holidayBTypeScore === holidayATypeScore) {
        return dateA.getTime() - dateB.getTime();
      }

      return holidayBTypeScore - holidayATypeScore;
    });
  }

  // Select the first match from the list sorted by current language (assumes it is sorted)
  const sortedMatches = holidayMatches.sort((a, b) => {
    const [, holidayKeyA] = a;
    const [, holidayKeyB] = b;

    const { languages: languagesA } = holidayMap.get(holidayKeyA);
    const { languages: languagesB } = holidayMap.get(holidayKeyB);

    const scoreA = languagesA.includes(userLang) ? 1 : 0;
    const scoreB = languagesB.includes(userLang) ? 1 : 0;
    const languageScore = scoreB - scoreA;

    if (languageScore !== 0) return languageScore;

    // If the score matches, find the mose specific list of languages
    return languagesA.length - languagesB.length;
  }); // First One

  const holidayMatch = sortedMatches[0];

  // If there is a match, try using it in sequence
  if (holidayMatch) {
    const [, holidayKey] = holidayMatch;
    const { holiday } = holidayMap.get(holidayKey);
    const { date: holidayDate, name: holidayName } = holiday;
    return [holidayDate, holidayName];
  }

  return null;
};
