import "./UserInteractionNudge.css";

import React, { useCallback, useEffect, useRef, useState } from "react";
import { CSSTransition } from "react-transition-group";

import { SECONDS_IN_MILLISECONDS } from "../../time/constants";
import ReactTooltip from "react-tooltip";
import { Keyboard, Mouse, SpeakerHigh } from "phosphor-react";

const UserInteractionNudge = ({
  hasInteracted = false,
  isPermanentlyDismissed = false,
  mutedPreference = false,
  setIsPermanentlyDismissed,
}) => {
  const [isDismissed, setIsDismissed] = useState(isPermanentlyDismissed);

  const onDismiss = useCallback(
    (event) => {
      setIsDismissed(true);
    },
    [setIsDismissed]
  );

  const onPermanentlyDismiss = useCallback(
    (event) => {
      setIsPermanentlyDismissed(true);
    },
    [setIsPermanentlyDismissed]
  );

  useEffect(() => {
    const hideTimeout = setTimeout(
      () => setIsDismissed(true),
      SECONDS_IN_MILLISECONDS * 12
    );
    return () => {
      clearTimeout(hideTimeout);
    };
  }, [setIsDismissed]);

  const nudgeRef = useRef();

  return (
    <>
      <CSSTransition
        appear
        classNames="EggTimer-nudge-transition"
        in={!hasInteracted && !mutedPreference && !isDismissed}
        key="nudge-key"
        nodeRef={nudgeRef}
        timeout={300}
        unmountOnExit
      >
        <div className="EggTimer-user-interaction-nudge" ref={nudgeRef}>
          <span>
            If you want <SpeakerHigh /> you need to <Mouse /> or <Keyboard />
          </span>
          <p>
            To enable audio alerts please click anywhere on the page or press
            any key.
          </p>
          <button
            className="EggTimer-user-interaction-nudge-permanent"
            data-for="nudge"
            data-tip="This banner will no longer appear but you will still have to interact to enable audio"
            disabled={isDismissed}
            id="gg-nudge-permanent"
            onClick={onPermanentlyDismiss}
            value="DismissNudgePermanently"
          >
            Do Not Show This Again
          </button>
          <button
            id="gg-nudge-enable"
            disabled={isDismissed}
            onClick={onDismiss}
            value="DismissNudge"
          >
            Enable Audio
          </button>
        </div>
      </CSSTransition>
      <ReactTooltip
        delayShow={120}
        id="nudge"
        place="top"
        type="dark"
        effect="solid"
      />
    </>
  );
};

export default React.memo(UserInteractionNudge);
