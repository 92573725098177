import React from "react";
import { Link } from "react-router-dom";
import {
  SOUND_PREFERENCE_VALUES,
  TIMER_PREFERENCE_VALUES,
} from "../../constants";
import { getTimerLabel } from "../timers/getTimer";
import { getSoundLabel } from "../timers/common/getSound";

function HelpSection({ onClickLink }) {
  return (
    <div className="EggTimer-help section">
      <h1>Help</h1>
      <article>
        <h2>Timer</h2>
        <h4>Enter a time and let the countdown begin</h4>
        <p>
          E.ggTimer is a countdown timer that aspires to be easy to use. The
          input accepts various types of time formats and the timer tries its
          best to figure out what you need. After you enter a time and press the
          "Start" button (or hit Enter) the timer will begin immediately.
        </p>
        <p>
          You can also set the time directly via the URL. For example{" "}
          <Link to="/12 minutes" target="_blank">
            /12 minutes
          </Link>{" "}
          will navigate to, and begin, a 12 minute timer.
        </p>
        <h4>Example time formats</h4>
        <ul>
          <li>
            <Link to="/30" target="_blank">
              30
            </Link>{" "}
            <i>(no unit will default to minutes)</i>
          </li>
          <li>
            <Link to="/15 minutes" target="_blank">
              15 minutes
            </Link>
          </li>
          <li>
            <Link to="/60 minutes" target="_blank">
              60 seconds
            </Link>
          </li>
          <li>
            <Link to="/5 days 3 hours" target="_blank">
              5 days 3 hours
            </Link>
          </li>
          <li>
            <Link to="/45m" target="_blank">
              45m
            </Link>{" "}
            <i>(shorthand unit)</i>
          </li>
          <li>
            <Link to="/9pm" target="_blank">
              9pm
            </Link>
          </li>
          <li>
            <Link to="/17:30" target="_blank">
              17:30
            </Link>{" "}
            <i>(i.e. 5:30pm)</i>
          </li>
          <li>
            <Link to="/2030-01-01" target="_blank">
              2030-01-01
            </Link>
          </li>
          <li>
            <Link to="/1 January 2030" target="_blank">
              1 January 2030
            </Link>
          </li>
          <li>
            <Link to="/week" target="_blank">
              week
            </Link>{" "}
            <i>(one week)</i>
          </li>
          <li>
            <Link to="/Mothers Day" target="_blank">
              Mothers Day
            </Link>{" "}
            <i>(a holiday)</i>
          </li>
        </ul>
        <h4>Special Timers and Holidays</h4>
        <p>
          There are some special timers, like{" "}
          <Link to="/pomodoro" target="_blank">
            /pomodoro
          </Link>{" "}
          that run a predefined sequence of timers. The timer input is also
          aware of some, but not all, holidays and will try to pick the most
          relevant holiday coming up in the future. For example{" "}
          <Link to="/new+years+day" target="_blank">
            /new years day
          </Link>
        </p>
      </article>
      <article>
        <h2>URL Parameters</h2>
        <h4>Adding a label</h4>
        <p>
          E.ggTimer supports adding a label to a timer via the URL. There are
          four main URL patterns that are supported. In the following example{" "}
          <code>(time value)</code> represents any valid timer value and{" "}
          <code>(label)</code> represents a url-safe string label.
        </p>
        <code className="example">
          Start with https://e.ggtimer.com <br />
          /(time value)/until/(label) <br />
          /(label)/for/(time value) <br />
          /(label)/in/(time value) <br />
          /(label)/at/(time value) <br />
        </code>
        <p>
          example:{" "}
          <Link to="/Party!/in/3minutes" target="_blank">
            /Party!/in/3minutes
          </Link>
        </p>
        <h4>Time Sequences</h4>
        <p>
          There is basic support for timer sequences. Beyond our names sequences like <Link to="/pomodoro"
                                                                                            target="_blank">Pomodoro</Link>, <Link
          to="/tabata" target="_blank">Tabata</Link>, and <Link to="/52-17" target="_blank">52-17</Link>{ " " }
          you can create your own sequence of simple times by separating them with the <code>/</code> symbol.
          <code className="example">
            /(time value 1)/(time value 2) <br/>
            /(time value 1)/(time value 2) <br/>
            /(time value 1)/(time value 2)/(time value 3)<br/>
            (and so on.) <br/>
          </code>
          <p>
            example:{ " " }
            <Link to="/1h/20m" target="_blank">
              /1h/20m
            </Link>
          </p>

        </p>
        <h4>Advanced Parameters</h4>
        <p>
          Beyond setting a time value and label there are some other
          customizations that are available via URL. These include ways to set
          initial volume, muted, and notification settings as well as the theme.
        </p>
        <div className="parameter">
          <p>
            <b>muted</b> - set the default muted state of the timer.
          </p>
          <p>
            <code>true</code> or <code>false</code> – default is false*. true
            means the timer is muted until the user manually switches.
          </p>
          <p>
            <strong> Please note: </strong>
            that many browser disable audio until the user has interacted with
            the page. Setting <code>muted</code> to false cannot override this
            browser behavior.
          </p>
          <code className="example">/(time value)?muted=true</code>
        </div>
        <div className="parameter">
          <p>
            <b>notify</b> - set the default notification state of the timer
          </p>
          <p>
            <code>true</code> or <code>false</code> – default is true*. false
            means the timer will not show a system notification at the end
            unless the user manually switches.
          </p>
          <code className="example">/(time value)?notify=false</code>
        </div>
        <div className="parameter">
          <p>
            <b>hideToolbar</b> - hide the toolbar by default
          </p>
          <p>
            <code>true</code> or <code>false</code> – default is false*. true
            means the toolbar will be hidden until the user opens it.
          </p>
          <code className="example">/(time value)?hideToolbar=true</code>
        </div>
        <div className="parameter">
          <p>
            <b>hideNudge</b> - hide the audio interaction reminder
          </p>
          <p>
            <code>true</code> or <code>false</code> – default is false*. true
            means that the audio interaction reminder will not appear. It will
            normally appear for at least 12 seconds.
          </p>
          <code className="example">/(time value)?hideNudge=true</code>
        </div>
        <div className="parameter">
          <p>
            <b>volume</b> - set the alert sound volume
          </p>
          <p>
            <code>0</code> through <code>1</code> – default is 1*. Setting a
            value changes the alert sound volume at the end of the timer.
          </p>
          <code className="example">/(time value)?volume=0.5</code>
        </div>
        <div className="parameter">
          <p>
            <b>theme</b> - set the timer theme
          </p>
          <p>
            {Object.keys(TIMER_PREFERENCE_VALUES).map((key) => {
              return (
                <React.Fragment key={key}>
                  <code>{TIMER_PREFERENCE_VALUES[key]}</code> ={" "}
                  {getTimerLabel(TIMER_PREFERENCE_VALUES[key])} <br />
                </React.Fragment>
              );
            })}
          </p>
          <p>
            default is gg_timer_classic*. Setting a theme sets the default theme
            until overridden by the user.
          </p>
          <code className="example">/(time value)?theme=gg_timer_digital</code>
        </div>
        <div className="parameter">
          <p>
            <b>sound</b> - set the alert sound
          </p>
          <p>
            {Object.keys(SOUND_PREFERENCE_VALUES).map((key) => {
              if (
                SOUND_PREFERENCE_VALUES[key] === SOUND_PREFERENCE_VALUES["1440"]
              )
                return null;
              return (
                <React.Fragment key={key}>
                  <code>{SOUND_PREFERENCE_VALUES[key]}</code> ={" "}
                  {getSoundLabel(SOUND_PREFERENCE_VALUES[key])} <br />
                </React.Fragment>
              );
            })}
          </p>
          <p>
            default is gg_timer_beep*. Setting a sound sets the default alert
            sound until overridden by the user.
          </p>
          <code className="example">/(time value)?sound=gg_timer_ring</code>
        </div>
        <i>
          * Unless the user has a specific locally saved preference, then the
          default is that preference
        </i>
      </article>
    </div>
  );
}

export default React.memo(HelpSection);
