import { useState } from "react";
import { getLocalStorageValue, setLocalStorageValue } from "../localStorage";

// Local Storage Hook
export function useLocalStorage(key, defaultValue, initialValue) {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState(() => {
    if (Boolean(initialValue)) return initialValue;
    return getLocalStorageValue(key, defaultValue);
  });

  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = (value) => {
    // Allow value to be a function so we have same API as useState
    const valueToStore = value instanceof Function ? value(storedValue) : value;
    // Save state
    setLocalStorageValue(key, valueToStore);
    setStoredValue(valueToStore);
  };

  return [storedValue, setValue];
}
