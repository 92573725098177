import "./TimerBar.css";

import React, { useCallback, useContext, useMemo } from "react";
import { Link } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import classnames from "classnames";
import {
  ArrowCircleLeft,
  ArrowCounterClockwise,
  CaretDown,
  CaretUp,
  Question,
} from "phosphor-react";
import TimeContext from "../../../context/TimeContext";
import { TIMER_LABELS } from "../getTimer";
import NotificationToggle from "../../ui/NotificationToggle";
import MuteToggle from "../../ui/MuteToggle";

function TimerBar({
  collapsed,
  muted,
  notify,
  notifyPrompt,
  resetTime,
  setCollapsed,
  setMuted,
  setNotify,
  setNotifyPrompt,
  setShowSettings,
  setTheme,
  theme,
}) {
  const timeDiff = useContext(TimeContext);

  const toggleCollapse = useCallback(() => {
    setCollapsed(!collapsed);
  }, [collapsed, setCollapsed]);

  const toggleMuted = useCallback(() => {
    setMuted(!muted);
  }, [muted, setMuted]);

  const toggleNotify = useCallback(() => {
    setNotify(!notify);
  }, [notify, setNotify]);

  const handleSetTheme = useCallback(
    (evt) => {
      const {
        target: { value },
      } = evt;
      setTheme(value);
    },
    [setTheme]
  );

  const handleRestartTimer = useCallback(() => {
    resetTime();
    ReactTooltip.hide();
  }, [resetTime]);

  const timerBarClassName = useMemo(() => {
    return classnames("EggTimer-timer-bar", {
      collapsed,
    });
  }, [collapsed]);

  const showSettings = useCallback(() => {
    setShowSettings(true);
  }, [setShowSettings]);

  return (
    <>
      <div className={timerBarClassName}>
        <div
          className="EggTimer-timer-bar-progress"
          style={{ width: `${timeDiff.percentComplete}%` }}
        />
        <div className="EggTimer-timer-bar-buttons">
          <Link
            className="EggTimer-timer-bar-back"
            data-for="timerbar"
            data-tip="Stop timer and return to home page"
            title="Stop timer and return to home page"
            to={process.env.PUBLIC_URL + "/"}
          >
            <ArrowCircleLeft />
          </Link>
          <button
            data-for="timerbar"
            data-tip="Restart timer"
            onClick={handleRestartTimer}
            title="Restart timer"
          >
            <ArrowCounterClockwise />
          </button>
          <select
            aria-label="Timer Theme"
            data-for="timerbar"
            data-tip="Change the timer theme"
            id="theme-select"
            onChange={handleSetTheme}
            title="Select a timer theme"
            value={theme}
          >
            {[...TIMER_LABELS.entries()].map(([key, label]) => {
              return (
                <option key={key} value={key}>
                  {label}
                </option>
              );
            })}
          </select>
          <MuteToggle muted={muted} onToggle={toggleMuted} />
          <NotificationToggle
            notify={notify}
            onToggle={toggleNotify}
            promptUser={!collapsed && notifyPrompt}
            onUserPrompted={setNotifyPrompt}
          />
          <button
            className="EggTimer-timer-bar-help"
            data-for="timerbar"
            data-tip="Help and Settings"
            onClick={showSettings}
            title="Help and Settings"
          >
            <Question />
          </button>
        </div>
        <div className="EggTimer-timer-bar-toggle">
          <button
            className="EggTimer-timer-bar-toggle-collapse"
            data-for="timerbar"
            data-tip="Toggle the toolbar"
            onClick={toggleCollapse}
            tabIndex="0"
          >
            {collapsed ? <CaretUp /> : <CaretDown />}
          </button>
        </div>
      </div>
      <ReactTooltip
        backgroundColor="#ff0095"
        delayShow={120}
        effect="solid"
        id="timerbar"
        place="top"
        type="dark"
      />
    </>
  );
}

export default React.memo(TimerBar);
