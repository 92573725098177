// Page constants
export const PAGE_TITLE = "e.ggtimer -";
export const DEFAULT_PAGE_TITLE = `${PAGE_TITLE} Simple Countdown Timer`;

// Timer Preference
export const TIMER_PREFERENCE_KEY = "gg_timer_preference";
export const TIMER_QUERY_PARAM = "theme";
export const TIMER_PREFERENCE_VALUES = {
  Ugly: "gg_timer_ugly",
  Classic: "gg_timer_classic",
  Digital: "gg_timer_digital",
  DotMatrix: "gg_timer_dotmatrix",
  LargeBar: "gg_timer_largebar",
  Sunset: "gg_timer_sunset",
  Slow: "gg_timer_slow",
  BSOD: "gg_timer_bsod",
};
export const TIMER_PREFERENCE_DEFAULT = TIMER_PREFERENCE_VALUES.Classic;

// Volume Preference
export const MUTED_PREFERENCE = "gg_volume_muted";
export const MUTED_QUERY_PARAM = "muted";
export const VOLUME_PREFERENCE = "gg_volume_value";
export const VOLUME_QUERY_PARAM = "volume";

// Notify Preference
export const NOTIFY_PREFERENCE = "gg_notify_enabled";
export const NOTIFY_QUERY_PARAM = "notify";

// Nudge Preference
export const USER_NUDGE_DISABLED_PREFERENCE = "gg_user_nudge_preference";
export const USER_NUDGE_DISABLED_QUERY_PARAM = "hideNudge";

// Toolbar Preference
export const TOOLBAR_TOGGLE_PREFERENCE = "gg_toolbar_collapsed";
export const TOOLBAR_QUERY_PARAM = "hideToolbar";

// Sound Preference
export const SOUND_PREFERENCE = "gg_toolbar_sound";
export const SOUND_QUERY_PARAM = "sound";
export const SOUND_PREFERENCE_VALUES = {
  BeepBeep: "gg_sound_beep",
  RingRing: "gg_sound_ring",
  1440: "gg_sound_1440",
};
export const SOUND_PREFERENCE_DEFAULT = SOUND_PREFERENCE_VALUES.BeepBeep;

// Death Time
export const DEATH_TIME = "gg_expiration";
