export const getQueryParam = (paramName, defaultValue = undefined) => {
  const params = new URLSearchParams(window.location.search);
  return params.get(paramName) || defaultValue;
};

export const setQueryParam = (paramName, value) => {
  const currentUrl = window.location.href;
  const url = new URL(window.location.href);
  const params = url.searchParams;
  if (params.has(paramName) && value === undefined) {
    params.delete(paramName);
  } else if (value !== undefined) {
    params.set(paramName, value);
  }

  if (url.toString() === currentUrl) return;
  window.history.replaceState("", "", `${url.toString()}`);
};
